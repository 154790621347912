import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Herosection = () => {
  return (
    <section className="font-sans overflow-x-hidden">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-blue-900 to-indigo-800 text-white overflow-hidden">
        {/* Background Video */}
        <div className="absolute inset-0 overflow-hidden">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
          >
            <source
              src="earth.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-24 md:py-32 relative z-10">
          <div className="flex flex-col md:flex-row items-center justify-between">
            {/* Left Side: Company Info */}
            <div className="w-full md:w-1/2 mb-12 md:mb-0">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
                  Diagnose. <br />Respond.<br />Improve.
                </h1>
                <p className="text-xl mb-8 text-gray-300">
                  Edu-best delivers effective solutions for enhancing 
                  <span className="block text-2xl mb-8 text-gray-300">
                    Reading comprehension skills.
                  </span>  
                </p>
              </motion.div>
            </div>

            {/* Right Side: Features */}
            <div className="w-full md:w-1/2 md:pl-12">
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-2xl"
              >
                <h2 className="text-2xl font-semibold mb-6">
                  We believe that improving reading comprehension skills profoundly impacts student learning across all disciplines and is essential to their growth as critical thinkers.
                </h2>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Decorative Element */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg
            viewBox="0 0 1440 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 120L60 105C120 90 240 60 360 45C480 30 600 30 720 37.5C840 45 960 60 1080 67.5C1200 75 1320 75 1380 75L1440 75V120H1380C1320 120 1200 120 1080 120C960 120 840 120 720 120C600 120 480 120 360 120C240 120 120 120 60 120H0Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      {/* End of Hero Section */}
    </section>
  );
};

export default Herosection;
