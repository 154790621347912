/* eslint-disable linebreak-style */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Button from '../elements/Button';

export default function Portfolio({ data }) {
  return (
    <section className="container mx-auto flex flex-col items-center mt-20 px-4">
      <Fade direction="right" triggerOnce>
        <h1 className="text-5xl text-theme-blue text-center font-bold mb-4">Tools for Effective Teaching and Learning</h1>
      </Fade>
      <Fade direction="left" triggerOnce>
        <p className="font-light text-lg text-gray-400 text-center mb-12">
          Focused Solutions for Schools, Teachers and Students
        </p>
      </Fade>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center w-full">
        {data.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fade
            direction="up"
            triggerOnce
            bottom
            delay={300 * index}
            key={index}
          >
            <Button type="link" href={`/products/${item.id}`} className="w-full">
              <div className="group rounded-2xl shadow-xl overflow-hidden transform transition duration-500 hover:scale-105 portofolio-card">
                <div className="flex flex-col md:flex-row">
                  {/* Image Section - 60% */}
                  <div className="md:w-3/5 relative">
                    <img
                      src={item.imageUrl}
                      alt={item.title}
                      className="w-full h-full object-cover rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl">
                      <button className="focus:outline-none">
                        <svg
                          className="w-12 h-12 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {/* Text Section - 40% */}
                  <div className="md:w-2/5 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-b-2xl md:rounded-l-none md:rounded-r-2xl p-4 flex flex-col justify-center">
                    <h2 className="text-theme-blue text-center md:text-left text-xl font-semibold mb-2">
                      {item.title}
                    </h2>
                    <p className="font-light text-gray-400 text-center md:text-left">
                      {item.type}
                    </p>
                  </div>
                </div>
              </div>
            </Button>
          </Fade>
        ))}
      </div>

      <Fade bottom triggerOnce>
        <Button
          href="/products"
          type="link"
          className="flex items-center justify-center w-36 h-12 sm:w-40 sm:h-14 lg:w-44 lg:h-16 xl:w-36 xl:h-12 text-theme-purple px-5 border border-theme-purple rounded-full mt-14 transition duration-300 hover:bg-theme-purple hover:text-white"
        >
          <p className="font-normal py-3 lg:text-base xl:text-sm">See Details</p>

          <svg
            className="w-4 h-4 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
          <svg
            className="w-4 h-4 -ml-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Button>
      </Fade>
    </section>
  );
}
