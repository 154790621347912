/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Footer from "../../Footer/Footer";

const SchoolDistrictResourcesPage = () => {
  // ------------------------------
  // Configuration
  // ------------------------------
  const SCHOOL_CATEGORY_ID = 175301277; // School & District Resources category ID
  const STORE_ID = '103479753'; // Your Ecwid Store ID

  // ------------------------------
  // Ecwid: Load script and initialize the product browser for our category
  // ------------------------------
  const storeRef = useRef(null);
  const cartRef = useRef(null);

  useEffect(() => {
    // Helper to load the Ecwid script
    const loadEcwidScript = () => {
      return new Promise((resolve, reject) => {
        if (document.getElementById('ecwid-script')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.id = 'ecwid-script';
        script.type = 'text/javascript';
        script.src = `https://app.ecwid.com/script.js?${STORE_ID}&data_platform=code&data_date=${new Date()
          .toISOString()
          .split('T')[0]}`;
        script.async = true;
        script.charset = 'utf-8';
        script.setAttribute('data-cfasync', 'false');

        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Failed to load Ecwid script.'));
        document.body.appendChild(script);
      });
    };
    window.addEventListener('error', (event) => {
      if (event.message && event.message.includes('ResizeObserver loop')) {
        // Prevent the error from being logged in the console.
        event.stopImmediatePropagation();
      }
    }, true);
    
    
    // Initialize Ecwid widgets once the API is loaded
    const initializeEcwidWidgets = () => {
      if (window.Ecwid && window.Ecwid.OnAPILoaded) {
        window.Ecwid.OnAPILoaded.add(() => {
          if (!window.ecwidInitialized) {
            if (window.xProductBrowser) {
              // Initialize the product browser filtered by our category
              window.xProductBrowser(
                "categoriesPerRow=3",
                "views=grid(20,3) list(60) table(60)",
                "categoryView=grid",
                "searchView=list",
                `categoryId=${SCHOOL_CATEGORY_ID}`,
                `id=my-store-${STORE_ID}`
              );
            } else {
              console.error('xProductBrowser function is not available.');
            }
            if (window.Ecwid && window.Ecwid.init) {
              window.Ecwid.init();
            } else {
              console.error('Ecwid.init function is not available.');
            }
            window.ecwidInitialized = true;
          }
        });
      } else {
        console.error('Ecwid object is not available on window.');
      }
    };

    loadEcwidScript()
      .then(() => {
        initializeEcwidWidgets();
      })
      .catch((error) => {
        console.error(error);
      });

    // Cleanup on unmount: clear the product browser container
    return () => {
      const storeContainer = document.getElementById(`my-store-${STORE_ID}`);
      if (storeContainer) {
        storeContainer.innerHTML = '';
      }
    };
  }, [STORE_ID, SCHOOL_CATEGORY_ID]);

  return (
    <motion.div
      className="shop-container min-h-screen bg-gray-100 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* The main content container */}
      <div>
        {/* Inline style to hide Recently Viewed Products */}
        <style>{`
          #rvp-products {
            display: none !important;
          }
        `}</style>

        {/* Ecwid Product Browser (filtered by category) */}
        <div id={`my-store-${STORE_ID}`} ref={storeRef} className="my-8" />

        {/* Ecwid Cart Widget */}
        <div
          className="ec-cart-widget fixed top-4 right-4 z-50 cursor-pointer"
          ref={cartRef}
        />

      
      </div>
    </motion.div>
  );
};

export default SchoolDistrictResourcesPage;
