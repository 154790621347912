import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BlogDetails from '../../../parts/BlogDetails';
import { Blog } from 'json/landingPageData';

const BlogDetailPage = () => {
  const { id } = useParams();
  const detailData = Blog.filter((item) => item.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Always pass an array. If no data found, pass an empty array.
  return (
    <>
      <BlogDetails data={detailData.length === 1 ? [detailData[0]] : []} />
    </>
  );
};

export default BlogDetailPage;
