import React from 'react';
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="overflow-x-hidden max-w-screen font-poppins">
      {/* Footer Top Section */}
      <div className="sm:flex justify-between py-10 px-8 grid bg-gray-900">
        <div className="text-white">
          <h1 className="w-full text-3xl font-bold cursor-pointer text-blue-400">
          Edu-Best Educational Resources and Services
          </h1>
          <p className="py-4">
          Edu-Best delivers effective solutions for students as they continue on the path to improved reading comprehension skills
          </p>
          <div className="flex md:w-[75%] justify-between my-6 text-blue-200">
            <a href="https://www.linkedin.com/in/brigitta-goerres-96623222/" aria-label="LinkedIn">
              <FaLinkedin size={30} className="cursor-pointer hover:text-blue-400 transition-colors duration-300" />
            </a>
            {/* You can add more social icons here if needed */}
          </div>
        </div>
        <div className="text-white sm:w-[900px] sm:ml-[18%] sm:mr-[12%] sm:pt-0">
          <div>
            <h1 className="sm:w-[30rem] md:text-4xl sm:text-3xl text-center text-2xl font-bold py-2">
              Supportive, focused, and responsive solutions
            </h1>
          </div>
        </div>
      </div>
      {/* Footer Top Section End */}

      {/* Footer Middle Section */}
      <div className="sm:mx-auto pt-10 pb-8 sm:grid flex-row lg:grid-cols-3 w-screen gap-8 text-gray-300 bg-gray-800">
        <div className="lg:col-span-3 sm:mr-20 flex justify-between max-w-screen sm:ml-20 ml-4 mb-4 sm:mb-0">
          {/* Support Section */}
          <div className="pr-4">
            <h6 className="font-medium text-blue-400">Support</h6>
            <ul>
            
              
            <li className="py-2 text-sm cursor-pointer font-semibold hover:text-blue-400 transition-colors duration-300">
                <a href="/contact" aria-label="Privacy Policy">
                  Contact Us
                </a>
              </li>
              {/* Added Privacy Policy Link */}
              <li className="py-2 text-sm cursor-pointer font-semibold hover:text-blue-400 transition-colors duration-300">
                <a href="/privacy" aria-label="Privacy Policy">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          {/* Company Section */}
          <div className="pr-4">
            <h6 className="font-medium text-blue-400"></h6>
            <ul>
              {/* <li className="py-2 text-sm cursor-pointer font-semibold hover:text-blue-400 transition-colors duration-300">
                About Us
              </li>
              <li className="py-2 text-sm cursor-pointer font-semibold hover:text-blue-400 transition-colors duration-300">
                Press
              </li> */}
              {/* You can add more company-related links here */}
            </ul>
          </div>
        </div>
        {/* Divider Line */}
        <div className="h-[1px] bg-gray-100/20 lg:col-span-3 w-full my-6"></div>
        {/* Copyright Section */}
        <p className='pt-4 text-gray-200/40 text-sm flex justify-center text-center lg:col-span-3'>
          © 2024 Edu-Best Educational Resources and Services. All Rights Reserved.
        </p>
      </div>
      {/* Footer Middle Section End */}
    </div>
  );
}

export default Footer;
