import React from "react";
import Navbar from "../../Navigation/Navbar";
import Footer from "../../Footer/Footer";
import i1 from '../../../images/Services/1.svg';
import i2 from "../../../images/Services/2.svg";
import i3 from "../../../images/Services/3.svg";
import i4 from "../../../images/Services/4.svg";
import Features from "../../../Features/Features";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      staggerChildren: 0.3
    }
  },
};

const cardVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      duration: 0.5
    }
  },
};

const headerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8
    }
  },
};

const Servicess = () => {
  return (
    <>
      <Navbar />
      <div className="max-w-screen overflow-hidden font-poppins">
        {/* Services Component Start */}
        <div className="sm:mt-36 mt-24">
          <motion.h1
            variants={headerVariants}
            initial="hidden"
            animate="visible"
            className="flex justify-center font-sans font-extrabold tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-3xl sm:text-3xl text-center leading-snug"
          >
            We recognize that the path to improving reading comprehension skills is not the same for everyone. Whether you are leading a group of learners, or taking the journey independently, we are here to help.
          </motion.h1>
        </div>

        <div className="pb-16">
          {/* Services Section with Animations */}
          <motion.section
            className="max-w-8xl mx-auto container bg-white pt-16"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <div>
           
              <motion.div
                tabIndex={0}
                aria-label="group of cards"
                className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4"
                variants={containerVariants}
              >
         
<motion.div
  tabIndex={0}
  aria-label="card 1"
  className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
  variants={cardVariants}
>
  <div className="w-20 h-20 relative mr-5">
    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
    <div className="absolute text-white bottom-0 left-0 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
      <img src={i1} alt="Assessment Tools" />
    </div>
  </div>
  <div className="w-10/12">
    <h2
      tabIndex={0}
      className="focus:outline-none text-lg font-bold leading-tight text-gray-800"
    >
      DIAGNOSTIC ASSESSMENTS
    </h2>
    <p
      tabIndex={0}
      className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
    >
      We create online assessments from Grades 3 to 12 that are administered to students to determine how well they are performing against the specific reading comprehension skills articulated in the curriculum.    <br />
      <a href="https://edu-best.com/products/rcat-access" className="text-indigo-500 hover:underline">Learn more</a>
    </p>
  </div>
</motion.div>


<motion.div
  tabIndex={0}
  aria-label="card 2"
  className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
  variants={cardVariants}
>
  <div className="w-20 h-20 relative mr-5">
    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
    <div className="absolute text-white bottom-0 left-0 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
      <img src={i2} alt="Professional Development" />
    </div>
  </div>
  <div className="w-10/12">
    <h2
      tabIndex={0}
      className="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
    >
      TEACHER SUPPORT
    </h2>
    <p
      tabIndex={0}
      className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
    >
      We have developed comprehensive lessons that focus on the five essential reading skill categories for use in the classroom. These teaching packages also include digital presentations, student activities and journals, and follow-up exercises and post-tests.  <br /> <a href="https://edu-best.com/products/skillbuilder-teacher" className="text-indigo-500 hover:underline">Learn more</a>
    </p>
  </div>
</motion.div>


<motion.div
  tabIndex={0}
  aria-label="card 3"
  className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
  variants={cardVariants}
>
  <div className="w-20 h-20 relative mr-5">
    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
    <div className="absolute text-white bottom-0 left-0 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
      <img src={i3} alt="Educational Materials" />
    </div>
  </div>
  <div className="w-10/12">
    <h2
      tabIndex={0}
      className="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
    >
      CLASSROOM ACTIVITIES
    </h2>
    <p
      tabIndex={0}
      className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
    >
      To save teachers the time required to build content to respond to the needs of their students, we have created packages of passage-based exercises from Grades 3 to 12. All questions are directly aligned to the essential skill categories which continue to be covered in the classroom.   <br /><a href="https://edu-best.com/products/reading-comprehension-success" className="text-indigo-500 hover:underline">Learn more </a>
    </p>
  </div>
</motion.div>


<motion.div
  tabIndex={0}
  aria-label="card 4"
  className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
  variants={cardVariants}
>
  <div className="w-20 h-20 relative mr-5">
    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
    <div className="absolute text-white bottom-0 left-0 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
      <img src={i4} alt="Support Services" />
    </div>
  </div>
  <div className="w-10/12">
    <h2
      tabIndex={0}
      className="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
    >
      STUDENT RESOURCES
    </h2>
    <p
      tabIndex={0}
      className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
    >
      We have a series of workbooks from Grades 3-12 to help students to learn about the reading comprehension skill categories and work through exercises that focus on what they need to know at each grade.   <br />
      <a href="https://edu-best.com/products/student-workbooks" className="text-indigo-500 hover:underline">Learn more</a>
    </p>
  </div>
</motion.div>

                
              </motion.div>
            </div>
          </motion.section>
        </div>
        {/* Services Component Ends */}

        {/* Features Component with Animation */}
        {/* <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Features />
        </motion.div> */}
        {/* Features Component Ends */}

        {/* Stats Component with Animations */}
       
        {/* Stats Component Ends */}

        <div className="w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
      </div>

    </>
  );
};

export default Servicess;
