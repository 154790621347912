/* eslint-disable import/extensions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useRef, useEffect } from 'react';
import Fade from 'react-awesome-reveal';
import NotFound from 'assets/images/NotFound.png';
import Button from 'elements/Button';
import emailjs from '@emailjs/browser';

export default function PortfolioDetail({ data }) {
  // State for gating the iframe for selected products
  const [showModal, setShowModal] = useState(false);
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [formSuccessMsg, setFormSuccessMsg] = useState('');
  const [formErrorMsg, setFormErrorMsg] = useState('');
  const modalFormRef = useRef();

  // On mount, if the user already filled out the contact form on any page,
  // set the iframe as visible.
  useEffect(() => {
    const storedPermission = localStorage.getItem('canSeeSchoolPricing');
    if (storedPermission === 'true') {
      setIsIframeVisible(true);
    }
  }, []);

  // Opens the modal form (triggered by clicking the Request Access button)
  const openModal = () => {
    setShowModal(true);
    setFormSuccessMsg('');
    setFormErrorMsg('');
  };

  // When the user clicks the close button in the modal, simply dismiss the modal.
  const closeModal = () => {
    setShowModal(false);
  };

  // Handle form submission using EmailJS.
  // On success, the iframe is revealed and the permission is stored.
  const handleModalSubmit = (e) => {
    e.preventDefault();

    // Replace with your actual EmailJS credentials
    const serviceID = 'service_veqv46p';
    const templateID = 'template_saj1jgs';
    const publicKey = 'bXst-qelDKIglcPNf';

    emailjs.sendForm(serviceID, templateID, modalFormRef.current, publicKey)
      .then(
        (result) => {
          console.log('EmailJS result:', result.text);
          setFormSuccessMsg("Thank you! Access granted.");
          setFormErrorMsg('');
          setIsIframeVisible(true);
          // Save permission so that other pages can read it
          localStorage.setItem('canSeeSchoolPricing', 'true');
          // Optionally, close the modal after a short delay
          setTimeout(() => {
            setShowModal(false);
          }, 1500);
        },
        (error) => {
          console.error('EmailJS error:', error.text);
          setFormErrorMsg("An error occurred. Please try again.");
          setFormSuccessMsg('');
        }
      );
  };

  // If no data, show the Not Found message
  if (data === null) {
    return (
      <section className="container mx-auto">
        <Fade bottom triggerOnce>
          <div className="flex flex-col w-full justify-center">
            <div className="flex w-full justify-center">
              <img src={NotFound} alt="Not Found" className="sm:w-3/4 xl:w-5/12 mt-5" />
            </div>
            <h1 className="text-theme-blue text-2xl text-center mt-5">
              Project Not Found
            </h1>
            <div className="flex justify-center">
              <Button
                href="/"
                type="link"
                className="flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-500 hover:bg-gray-900"
              >
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  // Define which products should be gated by the modal
  const gatedProductIds = ['rcat-access', 'skillbuilder-teacher', 'reading-comprehension-success'];

  return (
    <section className="container mx-auto">
      <Fade bottom>
        <Button
          type="link"
          href="/"
          className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline"
        >
          <svg className="w-5 h-5 text-gray-400 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </Button>
      </Fade>

      {data.map((item) => (
        <div key={item.id} className="flex flex-col mt-8 justify-center">
          <Fade bottom triggerOnce>
            <h1 className="text-5xl text-theme-blue text-center font-bold">{item.title}</h1>
            <p className="font-light text-xl text-gray-400 text-center mb-10">
              {item.type}
            </p>
          </Fade>

          <Fade bottom delay={300} triggerOnce>
            <div className="flex justify-center xl:mb-6">
              <img src={item.imageUrl} alt={item.title} className="flex w-1/5 mx-auto" />

            </div>
          </Fade>

          <Fade bottom delay={300} triggerOnce>
            <div className="flex flex-col mt-16 mb-12 mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-3">
                Details
              </h1>
              <p className="font-light text-lg text-gray-400 text-justify">
                {item.description}
              </p>
            </div>

            <div className="flex flex-col mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-5">
                Features
              </h1>
              <ul className="list-disc list-inside font-light text-gray-400">
                {item.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>

            {/* Render the iframe section only if a credit URL exists */}
            {item.credit && item.credit.trim() !== '' && (
              <div className="flex flex-col mx-8 sm:mx-16 xl:mx-28 mt-10">
                <h1 className="text-3xl text-theme-blue font-bold mb-5">
                  Order Now
                </h1>
                {gatedProductIds.includes(item.id) ? (
                  // For gated products, show a Request Access button if the iframe is not yet visible.
                  <>
                    {isIframeVisible ? (
                      <iframe
                        src={item.credit}
                        title={`${item.title} Store Page`}
                        className="w-full h-[600px] border-0 rounded"
                      />
                    ) : (
                      <Button
                        onClick={openModal}
                        type="button"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded px-6 py-2"
                      >
                        Request Access to Pricing
                      </Button>
                    )}
                  </>
                ) : (
                  // For all other products, render the iframe normally.
                  <iframe
                    src={item.credit}
                    title={`${item.title} Store Page`}
                    className="w-full h-[600px] border-0 rounded"
                  />
                )}
              </div>
            )}
          </Fade>
        </div>
      ))}

      {/* Modal for gating the iframe (only applicable for gated products) */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md mx-auto rounded-lg p-6 relative">
            {/* Close button: simply closes the modal */}
            <button
              onClick={closeModal}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Request Access to Pricing
            </h2>
            <form ref={modalFormRef} onSubmit={handleModalSubmit}>
              <div className="mb-4">
                <label className="block mb-1 font-medium">Your Name</label>
                <input
                  type="text"
                  name="user_name"
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1 font-medium">School Name</label>
                <input
                  type="text"
                  name="user_school"
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1 font-medium">Email</label>
                <input
                  type="email"
                  name="user_email"
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded px-6 py-2"
                >
                  Submit
                </button>
              </div>
            </form>
            {formSuccessMsg && (
              <p className="mt-4 text-green-600 text-center">{formSuccessMsg}</p>
            )}
            {formErrorMsg && (
              <p className="mt-4 text-red-600 text-center">{formErrorMsg}</p>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
