import React from "react";
import Navbar from "../../Navigation/Navbar";
import Herosection from "../../Herosection/Herosection";
import Features from "../../../Features/Features";
import CardComponent from "../../Price/CardComponent";
import CallToAction from "../../CallToAction/CallToAction";
import Review from "../../Review/Review";
import Footer from "../../Footer/Footer";
import {
  Services, Portfolios, Advantages, Testimonials,
} from 'json/landingPageData';
import Service from 'parts/Service';
import Portfolio from 'parts/Portfolio';
import Advantage from 'parts/Advantage';
import Testimonial from 'parts/Testimonial';
import Discuss from 'parts/Discuss';
import Servicess from '../Services/Servicess';
import { RxLinkedinLogo } from "react-icons/rx";
import profile from "../../../images/Features/profile.jpg";
import { inView, useAnimation, useInView, motion } from "framer-motion";
import { Fade } from 'react-awesome-reveal';
import Button from '../../../elements/Button';

const Home = () => {
  return (
    <>
      <Navbar />
      <Herosection />

      {/* Portfolio Section */}
    

      {/* NEW Skill Builder Teacher Packs Section */}
      <section className="text-black">
        <div className="container max-w-xl p-6 py-12 mx-auto lg:px-8 lg:max-w-7xl">
          {/* Flex container to place the heading and button on the same line */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-3xl font-bold tracking-tight text-center sm:text-5xl"
            >
              <motion.span
                animate={{
                  scale: [1, 1.2, 1],
                  color: ["#FF0000", "#00FF00", "#0000FF"],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "easeInOut",
                }}
                className="mr-2"
              >
                NEW
              </motion.span>
              Skill Builder Teacher Packs
            </motion.h2>
            <Fade bottom triggerOnce>
              <Button
                href="/skillbuilder"
                type="link"
                className="flex items-center justify-center w-36 h-12 sm:w-40 sm:h-14 lg:w-44 lg:h-16 xl:w-36 xl:h-12 text-theme-purple px-5 border border-theme-purple rounded-full transition duration-300 hover:bg-theme-purple hover:text-white"
              >
                <p className="font-normal py-3 lg:text-base xl:text-sm">See Details</p>
                <svg
                  className="w-4 h-4 ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
                <svg
                  className="w-4 h-4 -ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </Button>
            </Fade>
          </div>
        </div>
      </section>
      <Portfolio data={Portfolios} />
      {/* Other Sections */}
      <Servicess />
      {/* <Advantage data={Advantages} /> */}
      <>
        <div className="py-5 bg-gradient-to-r from-blue-400 to-violet-500"></div>
        <div className="py-10">
          <div className="container mx-auto text-center">
            <p className="text-gray-500 text-lg font-normal">
              Meet Brigitta Goerres – President and CEO
            </p>
            <h1 className="text-4xl text-gray-800 font-extrabold">
              Leading a team of talented and passionate education professionals!
            </h1>
          </div>
          <div className="w-full bg-gray-100 px-10 pt-10">
            <div className="container mx-auto">
              <div className="flex items-center justify-center">
                <div className="w-full lg:w-3/4">
                  <div className="rounded overflow-hidden shadow-md bg-white p-10">
                    <div className="flex flex-col items-center">
                      <img
                        src={profile}
                        alt="Brigitta Goerres"
                        className="rounded-full object-cover h-32 w-32 shadow-md mb-4"
                      />
                      <div className="text-center">
                        <h2 className="font-bold text-3xl pb-1">Brigitta Goerres</h2>
                        <p className="text-gray-800 text-sm">Educator</p>
                      </div>
                      <p className="text-gray-600 text-base pt-3 font-normal text-left">
                        Brigitta Goerres has been involved in education for over 35 years, starting her career as a classroom teacher immediately after graduating from the University of Alberta. Her professional journey led her to the Alberta Ministry of Education, after which she dedicated a decade to being the executive director of curriculum at an educational company. There she developed both print and digital study tools for students, teachers, and parents.
                        <br /><br />
                        After completing her graduate studies in Curriculum Studies at the University of Alberta, Brigitta worked with various assessment companies across North America and Europe, further broadening her expertise and impact in the field. Currently, Brigitta combines her experiences and has stepped back into the classroom, this time to an audience of diversified learners with unique backgrounds and goals.
                        <br /><br />
                        She has founded a consulting business to collaborate and share ideas and methods that will give all learners the opportunity to reach their highest level of achievement, as well as to inspire and motivate teachers. This initiative is part of her commitment to her own lifelong learning journey.
                        <br /><br />
                        Brigitta has authored 15 resources focused on reading comprehension success and conducts professional development workshops and courses for teachers at all levels. The Classroom Ready series represents the culmination of her experience. Working in partnership with educators and school districts, Brigitta Goerres has worked to develop the online tool RCAT, which serves as a platform for formative reading comprehension assessment, reporting, and monitoring of students’ performance.
                        <br /><br />
                        Brigitta’s journey in education is marked by extraordinary milestones, a journey she continues to pursue with excitement and passion.
                      </p>
                      <div className="w-full flex justify-center pt-5 pb-5">
                        <a href="https://www.linkedin.com/in/brigitta-goerres-96623222/" className="mx-5">
                          <RxLinkedinLogo
                            size={25}
                            className="text-gray-500 hover:text-purple-500"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonial data={Testimonials} />
      </>

      {/* <Review/> */}
      <Footer />
    </>
  );
};

export default Home;
