/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop type validation
import { Fade } from 'react-awesome-reveal'; // Corrected import for Fade
import NotFound from 'assets/images/NotFound.png';
import Button from 'elements/Button';

const BlogDetails = ({ data }) => {
  // Ensure 'data' is always an array. If not, default to an empty array.
  const blogData = Array.isArray(data) ? data : [];

  // If no blog data is found, display the "Blog Not Found" section
  if (blogData.length === 0) {
    return (
      <section className="container mx-auto">
        <Fade bottom triggerOnce>
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex w-full justify-center">
              <img src={NotFound} alt="Not Found" className="sm:w-3/4 xl:w-5/12 mt-5" />
            </div>
            <h1 className="text-theme-blue text-2xl text-center mt-5">
              Blog Not Found
            </h1>
            <div className="flex justify-center">
              <Button
                href="/"
                type="link"
                className="flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-500 hover:bg-gray-900"
              >
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  // If blog data is present, display the blog details
  return (
    <section className="container mx-auto">
      <Fade bottom>
        <Button
          type="link"
          href="/"
          className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline"
        >
          <svg
            className="w-5 h-5 text-gray-400 mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </Button>
      </Fade>

      {blogData.map((item) => (
        <div key={item.id} className="flex flex-col mt-8 justify-center">
          <Fade bottom triggerOnce>
            <h1 className="text-5xl text-theme-blue text-center font-bold">{item.title}</h1>

            <p className="font-light text-xl text-gray-400 text-center mb-10">
              {item.type}
            </p>
          </Fade>

          <Fade bottom delay={300} triggerOnce>
            <div className="flex justify-center xl:mb-6">
              <img src={item.imageUrl} alt={item.title} className="flex w-4/5 sm:w-4/6" />
            </div>
          </Fade>

          <Fade bottom delay={300} triggerOnce>
            <div className="flex flex-col mt-16 mb-12 mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-3">
                Blog
              </h1>

              <p className="font-light text-lg text-gray-400 text-justify">
                {item.description}
              </p>
            </div>

            <div className="flex flex-col mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-5">
                Project Features
              </h1>

              <ul className="list-disc list-inside font-light text-gray-400">
                {item.features && item.features.length > 0 ? (
                  item.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))
                ) : (
                  <li>No features available.</li>
                )}
              </ul>
            </div>
          </Fade>
        </div>
      ))}
    </section>
  );
};

// Define prop types for better type checking and developer experience
BlogDetails.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // Ensure 'id' is a string. Adjust if necessary
      title: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string),
      // Add other properties as needed
    })
  ),
};

// Define default props to ensure 'data' has a default value if not provided
BlogDetails.defaultProps = {
  data: [],
};

export default BlogDetails;
