/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from 'react';
import Navbar from "../../Navigation/Navbar";
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser'; // For sending the form
import Footer from "../../Footer/Footer";

const Shop = () => {
  const storeRef = useRef(null);
  const categoriesRef = useRef(null);
  const searchRef = useRef(null);
  const cartRef = useRef(null);

  // ------------------------------
  // 1) Handle the "School Resources" gating
  // ------------------------------
  // We'll store whether the user is allowed to see the School & District pricing
  const [canSeeSchoolPricing, setCanSeeSchoolPricing] = useState(false);

  // We'll track if the user is currently viewing a specific category.
  // For School & District Resources, we use ID: 175301277.
  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  // Check localStorage on mount to see if user previously filled out the form
  useEffect(() => {
    const storedPermission = localStorage.getItem('canSeeSchoolPricing');
    if (storedPermission === 'true') {
      setCanSeeSchoolPricing(true);
    }
  }, []);

  // ------------------------------
  // 2) Modal form for gating
  // ------------------------------
  const [showModal, setShowModal] = useState(false);
  const modalFormRef = useRef();
  const [formSuccessMsg, setFormSuccessMsg] = useState('');
  const [formErrorMsg, setFormErrorMsg] = useState('');

  const openModal = () => {
    setShowModal(true);
    setFormSuccessMsg('');
    setFormErrorMsg('');
  };

  // When the user clicks the cross button in the modal, redirect them
  const handleModalClose = () => {
    window.location.href = '/shop';
  };

  // (If you need to simply close the modal without redirecting, you can still use closeModal)
  const closeModal = () => {
    setShowModal(false);
    setFormSuccessMsg('');
    setFormErrorMsg('');
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();

    // Replace with your EmailJS credentials
    const serviceID = "service_veqv46p";
    const templateID = "template_saj1jgs";
    const publicKey = "bXst-qelDKIglcPNf";

    emailjs.sendForm(serviceID, templateID, modalFormRef.current, publicKey)
      .then(
        (result) => {
          console.log('EmailJS result:', result.text);
          setFormSuccessMsg("Thank you! We'll be in touch. Pricing is now visible.");
          setFormErrorMsg('');

          // Mark that user can see school pricing
          setCanSeeSchoolPricing(true);
          localStorage.setItem('canSeeSchoolPricing', 'true');

          // Optional: close the modal after a short timeout
          setTimeout(() => {
            closeModal();
          }, 1500);
        },
        (error) => {
          console.error('EmailJS error:', error.text);
          setFormErrorMsg("An error occurred. Please try again.");
          setFormSuccessMsg('');
        }
      );
  };

  // ------------------------------
  // 3) Ecwid initialization
  // ------------------------------
  useEffect(() => {
    const handleError = (e) => {
      if (
        e.message.includes('ResizeObserver loop limit exceeded') ||
        e.message.includes('ResizeObserver loop completed with undelivered notifications')
      ) {
        const overlayDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const overlay = document.getElementById('webpack-dev-server-client-overlay');
        if (overlay) {
          overlay.style.display = 'none';
        }
        if (overlayDiv) {
          overlayDiv.style.display = 'none';
        }
      }
    };

    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  useEffect(() => {
    const STORE_ID = '103479753'; // Replace with your actual Ecwid Store ID

    // Load the Ecwid script
    const loadEcwidScript = () => {
      return new Promise((resolve, reject) => {
        if (document.getElementById('ecwid-script')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.id = 'ecwid-script';
        script.type = 'text/javascript';
        script.src = `https://app.ecwid.com/script.js?${STORE_ID}&data_platform=code&data_date=${new Date().toISOString().split('T')[0]}`;
        script.async = true;
        script.charset = 'utf-8';
        script.setAttribute('data-cfasync', 'false');

        script.onload = () => {
          resolve();
        };

        script.onerror = () => {
          reject(new Error('Failed to load Ecwid script.'));
        };

        document.body.appendChild(script);
      });
    };

    // Initialize Ecwid widgets
    const initializeEcwidWidgets = () => {
      if (window.Ecwid && window.Ecwid.OnAPILoaded) {
        window.Ecwid.OnAPILoaded.add(() => {
          if (!window.ecwidInitialized) {
            // Category Navigation
            if (window.xCategoriesV2) {
              window.xCategoriesV2(`id=my-categories-${STORE_ID}`);
            } else {
              console.error('xCategoriesV2 function is not available.');
            }

            // Search
            if (window.xSearch) {
              window.xSearch(`id=my-search-${STORE_ID}`);
            } else {
              console.error('xSearch function is not available.');
            }

            // Product Browser
            if (window.xProductBrowser) {
              window.xProductBrowser(
                "categoriesPerRow=3",
                "views=grid(20,3) list(60) table(60)",
                "categoryView=grid",
                "searchView=list",
                `id=my-store-${STORE_ID}`
              );
            } else {
              console.error('xProductBrowser function is not available.');
            }

            // Ecwid init
            if (window.Ecwid && window.Ecwid.init) {
              window.Ecwid.init();
            } else {
              console.error('Ecwid.init function is not available.');
            }

            // Listen for page changes (to detect category ID)
            window.Ecwid.OnPageLoaded.add((page) => {
              if (page.type === 'CATEGORY') {
                setCurrentCategoryId(page.categoryId);
              } else if (page.type === 'SEARCH') {
                setCurrentCategoryId(null);
              }
              // You can add more checks here if needed
            });

            window.ecwidInitialized = true;
          }
        });
      } else {
        console.error('Ecwid object is not available on window.');
      }
    };

    loadEcwidScript()
      .then(() => {
        initializeEcwidWidgets();
      })
      .catch((error) => {
        console.error(error);
      });

    // Cleanup
    return () => {
      // Clear widget containers
      const storeContainer = document.getElementById(`my-store-${STORE_ID}`);
      if (storeContainer) {
        storeContainer.innerHTML = '';
      }
      const categoriesContainer = document.getElementById(`my-categories-${STORE_ID}`);
      if (categoriesContainer) {
        categoriesContainer.innerHTML = '';
      }
      const searchContainer = document.getElementById(`my-search-${STORE_ID}`);
      if (searchContainer) {
        searchContainer.innerHTML = '';
      }
    };
  }, []);

  // ------------------------------
  // 4) Decide if we need to open the modal
  //    whenever user is in School & District Resources category and hasn't filled the form
  // ------------------------------
  useEffect(() => {
    const SCHOOL_CATEGORY_ID = 175301277; // School & District Resources category ID
    if (currentCategoryId === SCHOOL_CATEGORY_ID && !canSeeSchoolPricing) {
      // Show the modal forcing them to fill the form
      openModal();
    }
  }, [currentCategoryId, canSeeSchoolPricing]);

  // Determine if we should apply a blur to the background
  const shouldBlur = currentCategoryId === 175301277 && !canSeeSchoolPricing;

  return (
    <motion.div
      className="shop-container min-h-screen bg-gray-100 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Wrap all background content in a container that blurs conditionally */}
      <div className={shouldBlur ? "filter blur-xl transition-all duration-300" : ""}>
        <Navbar />

        {/* --- The Ecwid Category Navigation --- */}
        <div
          id="my-categories-103479753"
          ref={categoriesRef}
          className="my-4"
        />

        {/* --- The Ecwid Search Box --- */}
        <div
          id="my-search-103479753"
          ref={searchRef}
          className="my-4 flex justify-center"
        />

        {/*
           Hide pricing for School & District Resources if the user hasn't submitted the form.
           This applies when the current category ID is 175301277.
        */}
<style>{`
  ${currentCategoryId === 175301277 && !canSeeSchoolPricing
    ? `.ec-price, .ec-product__price--special { 
         visibility: hidden !important; 
       }`
    : ""
  }
`}</style>


        {/*
           Hide Recently Viewed Products.
           Adjust the selector if Ecwid uses a different class or ID.
        */}
  <style>{`
  #rvp-products {
    display: none !important;
  }
`}</style>



        {/* --- The Ecwid Product Browser --- */}
        <div
          id="my-store-103479753"
          ref={storeRef}
          className="my-8"
        />

        {/* --- The Ecwid Cart Widget --- */}
        <div
          className="ec-cart-widget fixed top-4 right-4 z-50 cursor-pointer"
          ref={cartRef}
        />

        <Footer />
      </div>

      {/* --- Our custom modal for gating School & District category pricing --- */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md mx-auto rounded-lg p-6 relative">
            {/* When the cross is clicked, redirect the user */}
            <button
              onClick={handleModalClose}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Request Access to School & District Pricing
            </h2>
            <form ref={modalFormRef} onSubmit={handleModalSubmit}>
              <div className="mb-4">
                <label className="block mb-1 font-medium">
                  Your Name
                </label>
                <input
                  type="text"
                  name="user_name"
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1 font-medium">
                  School Name
                </label>
                <input
                  type="text"
                  name="user_school"
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1 font-medium">
                  Email
                </label>
                <input
                  type="email"
                  name="user_email"
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded px-6 py-2"
                >
                  Submit
                </button>
              </div>
            </form>

            {formSuccessMsg && (
              <p className="mt-4 text-green-600 text-center">{formSuccessMsg}</p>
            )}
            {formErrorMsg && (
              <p className="mt-4 text-red-600 text-center">{formErrorMsg}</p>
            )}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Shop;
