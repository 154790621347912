/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from '../../../parts/Header';
import Bloghero from '../../../parts/Bloghero';

import AllBlog from '../../../parts/AllBlog';

import { Blog } from 'json/landingPageData';
import Navbar from "../../Navigation/Navbar";
export default class NewsandEvents extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Navbar />
        <Bloghero />
        <AllBlog data={Blog} />
 
   
      </>
    );
  }
}
