/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-boolean-value */
// eslint-disable-next-line react/no-array-index-key
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import { Fade } from 'react-awesome-reveal';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Pagination, Scrollbar, A11y, Autoplay,
} from 'swiper/modules';
import { inView, useAnimation, useInView, motion } from "framer-motion";
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Testimonial({ data }) {
  return (
    <section className="container mx-auto">
      <Fade direction="up">
        <h1 className="text-5xl text-theme-blue text-center font-bold">Testimonials</h1>
      </Fade>
      <Fade direction="up" delay={500}>
        <p className="font-light text-lg text-gray-400 text-center mb-3 sm:mb-3 xl:mb-4">
          What they said about us.
        </p>
      </Fade>
      {/* eslint-disable-next-line react/jsx-max-props-per-line */}
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        className="mySwiper"
        loop={true}
        navigation={true}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="flex-col xl:w-full rounded-2xl shadow-xl sm:shadow-2xl border px-8 py-6 mx-2 mb-6 mt-6 xl:mx-auto sm:mx-6 sm:mb-12">
              <div className="flex items-center mb-5">
                <img src={item.imageUrl} alt="Testimoni" className="w-20 h-20 rounded-full" />
                <div className="flex-col pl-5">
                  <h2 className="text-theme-blue text-2xl">{item.name}</h2>
                  <p className="font-light text-gray-400">{item.company}</p>
                </div>
              </div>
              <p className="font-light text-2xl text-gray-400 pl-5 pt-3 pb-1">
                {item.testimoni}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <motion.div
  className="px-2 py-5 mx-auto max-w-5xl"
  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }}
  viewport={{ once: true }}
  transition={{ duration: 1 }}
>
  <h1 className="xl:text-3xl md:text-2xl text-xl font-semibold leading-tight text-center text-gray-800 sm:mb-0 mb-8">
    Enhancing Educational Outcomes Globally
  </h1>
  <div className="mt-4 relative sm:flex items-center justify-center">
    <img
      src="https://i.ibb.co/KjrPCyW/map.png"
      alt="world map image"
      className="w-full xl:h-full h-64 object-cover object-fill sm:block hidden"
    />
    <img
      src="https://i.ibb.co/SXKj9Mf/map-bg.png"
      alt="mobile-image"
      className="sm:hidden -mt-8 block w-full h-64 object-cover object-fill absolute z-0"
    />
    <motion.div
      className="shadow-lg xl:p-4 p-3 sm:w-auto w-full bg-white sm:absolute relative z-20 sm:mt-0 mt-3 left-0 xl:ml-28 sm:ml-6 xl:-mt-20 sm:-mt-8"
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <p className="text-2xl font-semibold text-gray-800">200+ schools</p>
      <p className="text-sm leading-4 xl:mt-2 mt-1 text-gray-600">
        Schools Using RCAT
      </p>
    </motion.div>
    <motion.div
      className="shadow-lg xl:p-4 p-3 w-40 sm:w-auto w-full bg-white sm:absolute relative z-20 sm:mt-0 mt-3 xl:mt-40 sm:mt-28 xl:-ml-0 sm:-ml-6"
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <p className="text-2xl font-semibold text-gray-800">120,000+ student users</p>
      <p className="text-sm leading-4 xl:mt-2 mt-1 text-gray-600">
        Student Engagement
      </p>
    </motion.div>
    <motion.div
      className="shadow-lg xl:p-4 p-3 sm:w-auto w-full bg-white sm:absolute relative z-20 md:mt-0 sm:-mt-3 mt-3 right-0 xl:mr-28 sm:mr-12"
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      <p className="text-2xl font-semibold text-gray-800">1,000+ teachers</p>
      <p className="text-sm leading-4 xl:mt-2 mt-1 text-gray-600">
        Supporting Educators
      </p>
    </motion.div>
  </div>

</motion.div>

    </section>
  );
}
