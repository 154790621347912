
import { motion } from "framer-motion";
import Fade from 'react-awesome-reveal';

import Button from 'elements/Button';

// Import only the assets used in the Skill Builder section.
import logo1 from "../../images/Features/f1.png";
import logo2 from "../../images/Features/f2.png";
import logo3 from "../../images/Features/f3.png";
import logo4 from "../../images/Features/f4.png";

import teacherManual from "../../images/teacherManual.pdf";
import activitiesAssessments from "../../images/activitiesAssessments.pdf";
import pptLessons from "../../images/pptLessons.pdf";

const SkillBuilder = () => {
  const skillBuilderComponents = [
    {
      title: "Teacher Manual - Sample",
      description:
        "Includes an Introduction to each of the Reading Comprehension Skill Categories; a summary of each of the lessons and student activities; alignment to the Alberta Grade 6 Curriculum for each Reading Skill Category Lesson package; suggested content from Reading Comprehension Success Blackline Master Packages for additional practice in each reading comprehension skill category; Answer Keys for Student Skills Check, Student Journal Activities; Student Unit Test; and Student Post-test.",
      icon: logo3,
      pdfLink: teacherManual,
    },
    {
      title: "Student Journal",
      description:
        "A PDF of student reading activities and writing activities to be completed after each lesson or groups of lessons within the PowerPoint Presentations. These activities can be assigned to students individually or in small groups to allow for discussion and critical thinking participation. You may choose to use any of the activities as formative or summative assignments. The PDFs can be printed and given to students or shared digitally with students to work online.",
      icon: logo2,
    },
    {
      title: "Assessment Section - Sample",
      description:
        "Includes PDFs of Student Skill Check, the Student Unit Tests for each of the 5 Reading Skill Categories, and the Student Post-Test. Please note that Answer Keys for Student Skills Check, Student Unit Tests; and Student Post-test are only available in the Teacher Manual.",
      icon: logo1,
      pdfLink: activitiesAssessments,
    },
    {
      title: "PowerPoint Presentation Lessons - Sample",
      description:
        "Includes 5 mini-lesson packages on each of the Reading Comprehension Skill Categories. These can be modified to suit your classroom needs. These can be taught in any order that works best for you. The units can be taught within a 3-to-4-week time frame, one unit taught every month, or units taught as required throughout the year. Please infuse your own content and add in novel studies, short story units, poetry units etc. to demonstrate the significance and application of reading comprehension.",
      icon: logo4,
      pdfLink: pptLessons,
    },
  ];

  return (
    <>
      {/* Top divider */}
      <div className="w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
      
      {/* Back Button */}
      <Fade bottom>
        <Button
          type="link"
          href="/"
          className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline"
        >
          <svg
            className="w-5 h-5 text-gray-400 mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </Button>
      </Fade>
      
      <div className="max-w-screen overflow-x-hidden font-poppins">
        <section className="text-black">
          <div className="container max-w-xl p-6 py-12 mx-auto space-y-8 lg:px-8 lg:max-w-7xl">
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-3xl font-bold tracking-tight text-center sm:text-5xl"
            >
              <motion.span
                animate={{
                  scale: [1, 1.2, 1],
                  color: ["#FF0000", "#00FF00", "#0000FF"],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "easeInOut",
                }}
                className="mr-2"
              >
                NEW
              </motion.span>
              Skill Builder Teacher Packs
            </motion.h2>
            <div className="space-y-8">
              {skillBuilderComponents.map((component, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex-shrink-0">
                    <img
                      src={component.icon}
                      alt={component.title}
                      className="w-12 h-12 mr-4"
                    />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">
                      {component.pdfLink ? (
                        <a
                          href={component.pdfLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          {component.title}
                        </a>
                      ) : (
                        component.title
                      )}
                    </h3>
                    <p className="mt-2 text-base whitespace-pre-wrap">
                      {component.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      
      {/* Bottom divider */}
      <div className="flex justify-end ml-[30%] w-[70%] h-[2px] bg-gradient-to-r from-blue-400 to-violet-500"></div>
    </>
  );
};

export default SkillBuilder;
