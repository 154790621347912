import React, { useRef, useState, useEffect, forwardRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Icon from "../../images/level.png"; // Ensure the path is correct

const tabs = [
  { name: 'Home', path: '/' },
  { name: 'Products', path: '/shop' },
  { name: 'News & Events', path: '/news-&-events' },
  { name: 'Contact', path: '/contact' },
];

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [cursorProps, setCursorProps] = useState({
    left: 0,
    width: 0,
    opacity: 0,
  });

  const location = useLocation();
  const tabRefs = useRef([]);

  // Set cursor to active tab on mount and when location changes
  useEffect(() => {
    const activeIndex = tabs.findIndex(tab => tab.path === location.pathname);
    if (activeIndex !== -1 && tabRefs.current[activeIndex]) {
      const { offsetLeft, clientWidth } = tabRefs.current[activeIndex];
      setCursorProps({
        left: offsetLeft,
        width: clientWidth,
        opacity: 1,
      });
    } else {
      setCursorProps(prev => ({ ...prev, opacity: 0 }));
    }
  }, [location.pathname, tabs]);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setOpen(!open);
  };

  // Handle mouse leave to set cursor to active tab
  const handleMouseLeave = () => {
    const activeIndex = tabs.findIndex(tab => tab.path === location.pathname);
    if (activeIndex !== -1 && tabRefs.current[activeIndex]) {
      const { offsetLeft, clientWidth } = tabRefs.current[activeIndex];
      setCursorProps({
        left: offsetLeft,
        width: clientWidth,
        opacity: 1,
      });
    } else {
      setCursorProps(prev => ({ ...prev, opacity: 0 }));
    }
  };

  return (
    <div className="max-w-screen overflow-x-hidden font-poppins">
      <nav className="bg-white fixed w-full z-20 top-0 left-0 right-0 border-b border-gray-200 shadow-md">
        {/* Desktop Navbar */}
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img
              src={Icon}
              alt="Edu-Best Logo"
              className="hidden sm:block w-9 h-9"
            />
            <span className="ml-2 text-gray-800 font-semibold text-xl">
              Edu-Best
            </span>
          </Link>
          {/* Navigation Links with Sliding Cursor */}
          <div className="hidden sm:flex relative">
            <ul
              onMouseLeave={handleMouseLeave}
              className="relative mx-auto flex w-fit rounded-full border-2 border-black bg-white p-1"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={tab.name}
                  ref={el => (tabRefs.current[index] = el)}
                  to={tab.path}
                  setCursorProps={setCursorProps}
                  isActive={location.pathname === tab.path}
                >
                  {tab.name}
                </Tab>
              ))}
              <Cursor position={cursorProps} />
            </ul>
          </div>
          {/* Hamburger Menu Icon */}
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              aria-label="Toggle menu"
              className="text-gray-800 focus:outline-none"
            >
              <GiHamburgerMenu size={24} />
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="sm:hidden bg-white border-t border-gray-200 shadow-md"
            >
              <ul className="flex flex-col px-4 py-2 space-y-2">
                {tabs.map(tab => (
                  <MobileTab key={tab.name} to={tab.path} onClick={toggleMenu}>
                    {tab.name}
                  </MobileTab>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
      {/* Spacer to prevent content from being hidden behind the fixed navbar */}
      <div className="h-20"></div>
    </div>
  );
};

// Modified Tab Component for Desktop
// We use useLocation to determine the current path, and for the "Products" tab,
// if the current path is not already '/shop', we force a full page refresh once the tab is clicked.
const Tab = forwardRef(({ children, to, setCursorProps, isActive }, ref) => {
  const location = useLocation();

  const handleMouseEnter = () => {
    if (!ref.current) return;
    const { offsetLeft, clientWidth } = ref.current;
    setCursorProps({
      left: offsetLeft,
      width: clientWidth,
      opacity: 1,
    });
  };

  const handleClick = () => {
    // Check if this is the "Products" tab and if the user is navigating from a different page.
    if (to === "/shop" && location.pathname !== "/shop") {
      // Use a timeout to let the navigation complete before forcing a reload.
      setTimeout(() => {
        window.location.reload();
      }, 0);
    }
  };

  return (
    <li
      ref={ref}
      onMouseEnter={handleMouseEnter}
      className="relative z-10 block cursor-pointer px-3 py-1.5 text-xs uppercase text-white md:px-5 md:py-3 md:text-base"
    >
      <Link
        to={to}
        onClick={handleClick}
        className={`block ${isActive ? "text-blue-500" : "text-gray-800"} hover:text-blue-500 transition-colors duration-300`}
      >
        {children}
      </Link>
    </li>
  );
});

// Mobile Tab Component
const MobileTab = ({ children, onClick, to }) => {
  return (
    <li className="relative z-10 block cursor-pointer px-3 py-1.5 text-base uppercase text-gray-800 hover:text-blue-500 transition-colors duration-300">
      <Link to={to} onClick={onClick} className="block">
        {children}
      </Link>
    </li>
  );
};

// Cursor Component
const Cursor = ({ position }) => {
  return (
    <motion.li
      animate={{
        left: position.left,
        width: position.width,
        opacity: position.opacity,
      }}
      transition={{ type: "spring", stiffness: 500, damping: 30 }}
      className="absolute z-0 h-7 rounded-full bg-black md:h-12"
    />
  );
};

export default Navbar;

/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
