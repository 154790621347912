import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from '../src/Components/Pages/Homepage/Home'
import About from '../src/Components/Pages/About/About'
import Services from './Components/Pages/Services/Servicess'
import Contact from '../src/Components/Pages/Contact/Contact'
import PrivacyPolicy from '../src/Components/Pages/Privacy/Privacy'
import './App.css';
import ScrollToTop from './useScrollToTop'
import Shop from '../src/Components/Pages/Shop/Shop'
import NewsandEvents from './Components/Pages/NewsandEvents/NewsandEvents'
import SkillBuilder from './Components/Pages/SkillBuilder';
import ProjectPage from '../src/Components/Pages/ProjectPage';
import NotFoundPage from '../src/Components/Pages/NotFoundPage';
import TeamPage from '../src/Components/Pages/TeamPage';
import { ProjectDetailPage } from '../src/Components/Pages/ProjectDetailPage';
import { DiscussProjectPage } from '../src/Components/Pages/DiscussProjectPage';
import BlogDetailPage from './Components/Pages/NewsandEvents/BlogDetailPage';
import SchoolDistrictResourcesPage from './Components/Pages/Product/Product';
function App() {

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/shop" element={<Shop />} />
        <Route exact path="/news-&-events" element={<NewsandEvents />} />
        <Route exact path="/news-&-events/:id" element={<BlogDetailPage />} />
        <Route path="/products" element={<ProjectPage />} />
        <Route path="/product" element={<SchoolDistrictResourcesPage />} />
        <Route exact path="/products/:id" element={<ProjectDetailPage />} />
        <Route path="/skillbuilder" element={<SkillBuilder />} />
        <Route exact path="/team" element={<TeamPage />} />
        <Route exact path="/discuss-project" element={<DiscussProjectPage />} />
        <Route path="*/*" element={<NotFoundPage />} />

                
      </Routes>
    </BrowserRouter>
  );
}

export default App;
