/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';

// Portfolio Img Imports - Keeping existing images
import blog from '../assets/images/Portfolio/blog.jpg';
import Recruiting from '../assets/images/Portfolio/rcat.jpeg';
import Stream from '../assets/images/Portfolio/pp.jpg';
import Freelance from '../assets/images/Portfolio/skill.jpeg';
import workbook from '../assets/images/Portfolio/gg1.jpg';
import Aura from '../assets/images/Portfolio/aura.jpg';
import Distrubtion from '../assets/images/Portfolio/Distrubtion.jpg';
import Surtido from '../assets/images/Portfolio/Surtido.png';
import ManagementApp from '../assets/images/Portfolio/ManagementApp.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.jpg';
import ProjectManager from '../assets/images/TeamMembers/Project-manager.jpg';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.jpg';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.jpg';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.jpg';
import Mobile1 from '../assets/images/TeamMembers/Mobile1.jpg';
import Mobile2 from '../assets/images/TeamMembers/Mobile2.jpg';
import UIUX1 from '../assets/images/TeamMembers/UIUX1.jpg';
import UIUX2 from '../assets/images/TeamMembers/UIUX2.jpg';
import DefaultImage from "../images/profile.png";
/**
 * Existing Services Data
 */
export const Services = [
  {
    title: 'Comprehensive Assessments',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Teacher Training Workshops',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'Ongoing Support and Guidance',
    imageUrl: UIUX,
    animation: 'right',
  },
];

/**
 * **Updated Portfolios Array with Your Product Data**
 * 
 * **Note:** We're reusing existing images for your products.
 * Ensure that the images align with the product descriptions for better user experience.
 */
export const Portfolios = [

  {
    id: 'rcat-access',
    title: 'Reading Comprehension Assessment Tool',
    imageUrl: Recruiting, // Reusing 'Recruiting.png' image
    type: 'Digital Assessments',
    description: 'Educators use our Reading Comprehension Assessment Tool (RCAT) to effectively diagnose reading comprehension skills and identify specific learning gaps.',
    price: '',
    features: [
      'Digital Assessments for Grades 3-12',
      'Includes Screening Tests, Level Tests and Passage Tests',
      'Secure Online Access',
      'Aligned to Curriculum Standards',
      'Dynamic Data Analysis and Reporting',

    ],
    credit: 'https://edu-best.com/product/#!/RCAT/p/713157411', // Replace with actual credit link if applicable
  },
  
  {
    id: 'skillbuilder-teacher',
    title: 'Skill Builder Teacher Packs',
    imageUrl: Freelance, // Reusing 'Freelance.png' image
    type: 'Lessons and Activities',
    description: 'Introduces each of the Reading Comprehension Skill Categories, demonstrates the alignment to curriculum standards, and provides focused lessons and student activities.',
    price: 'Volume Pricing',
    features: [
      'Curated Content for Grades 3-12',
      'Narrative, Informational, and Poetic Texts',
      'Over 300 Questions per Grade',
      'Skills-Based Approach',
      'Comprehensive Answer Keys',
      'Print and Digital Formats',
    ],
    credit: 'https://edu-best.com/product/#!/Skill-Builder-Teacher-Pack/p/713153130', // Replace with actual credit link if applicable
  },
  {
    id: 'reading-comprehension-success',
    title: 'Blackline Master Packages',
    imageUrl: Aura, // Reusing 'Aura.png' image
    type: 'Reproducible Worksheets',
    description: 'Use our comprehensive printed materials from grades 3-12 to reinforce key reading skills and provide additional practice.',
    price: '',
    features: [
      'Available for grades 3 to 12',
      'Narrative, Informational, and Poetic Texts',
      'Hundreds of well-performing and discerning assessment questions',
      'Answer key with detailed explanations',
      'Alignment to the reading comprehension skills assessed',
      'Fully reproducible under perpetual license agreement in print or digital format',
      'License for the content will not expire',
    ],
    credit: 'https://edu-best.com/product/#!/Blackline-Master-Package/p/713156359', // Replace with actual credit link if applicable
  },
  {
    id: 'student-workbooks',
    title: 'Student Workbooks',
    imageUrl: workbook, // Reusing 'Freelance.png' image
    type: 'Educational Materials',
    description: 'Curated content for grades 3-12 with narratives, informational, and poetic texts.',
    price: 'Volume Pricing',
    features: [
      'Curated Content for Grades 3-12',
      'Narrative, Informational, and Poetic Texts',
      'Hundreds of Questions per Grade',
      'Skills-Based Approach',
      'Comprehensive Answer Keys',
      'Coil Bound Format',
    ],
    credit: 'https://edu-best.com/product/#!/Books/c/175298787', // Replace with actual credit link if applicable
  },
  
  {
    id: 'teacher-workshops',
    title: 'Professional Development for Educators',
    imageUrl: Stream, // Reusing 'Stream.png' image
    type: 'Learning Workshops',
    description: 'Targeted workshops and training sessions designed to enhance teaching strategies and reading comprehension skills instruction.',
    price: 'Contact for Pricing',
    features: [
      'Professional Development Sessions',
      'Focus on Reading Comprehension',
      'Tailored to School Needs',
      'Led by Experienced Educators',
      'Interactive and Practical',
      'Certification Available',
    ],
    // credit: 'https://yourwebsite.com/teacher-workshops', // Replace with actual credit link if applicable
  },
  {
    id: 'distrubtion-partner',
    title: ' Distribution Partners-coming soon',
    imageUrl: Distrubtion, // Reusing 'Stream.png' image
    type: 'Coming Soon',
    description: 'Coming Soon',
    price: '',
    features: [
      'Coming Soon',
     
    ],
    // credit: 'https://yourwebsite.com/teacher-workshops', // Replace with actual credit link if applicable
  },
];

/**
 * Existing Advantages Data
 */
export const Advantages = [
  [{
    title: 'Communicative',
    description: 'We communicate and progress to make it clear.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'We manage our systems properly.',
    imageUrl: Management,
  }],
  [{
    title: 'Collaborative​',
    description: 'Our team are very collaborative to help you',
    imageUrl: Collaborative,
  },
  {
    title: 'Favorite',
    description: "all of our clients love us.",
    imageUrl: Favorite,
  }],
];

/**
 * Existing Testimonials Data
 */
export const Testimonials = [
  {
    id: 1,
    name: 'High School Teacher',
    company: 'High School',
    testimoni:
      'Thank you for this valuable session - we learned so much! Your session was a highlight for us at the convention.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 2,
    name: 'High School Educator',
    company: 'High School',
    testimoni:
      'Attending your session at the Mighty Peace Teachers Convention was invaluable. I plan to implement your strategies into our curriculum.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 3,
    name: 'Department Head, Humanities',
    company: 'High School',
    testimoni:
      'We’re really enjoying your master packages; they’re helping our students achieve better results.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 4,
    name: 'Junior High School Administrator',
    company: 'Junior High School',
    testimoni:
      "We would like to proceed with the 5-year license to the RCAT. It’s been a fantastic tool for our school's data collection.",
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 5,
    name: 'Online School Teacher',
    company: 'Online School',
    testimoni:
      'Your resources are phenomenal and have greatly enhanced our online teaching experience.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 6,
    name: 'School District Administrator',
    company: 'School District',
    testimoni:
      'The flexibility of your program is appreciated. It’s easy to use and provides clear data for our students.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 7,
    name: 'Junior High School Teacher',
    company: 'Junior High School',
    testimoni:
      'We’re finding the RCAT very useful this year, especially as we move towards a paperless system.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
  {
    id: 8,
    name: 'ELL Designate/ELA Teacher',
    company: 'High School',
    testimoni:
      'Thank you so much for your resources; they have been a tremendous asset to our teaching methods.',
    imageUrl: DefaultImage, // Replace with specific image if available
  },
];

/**
 * Existing Team Members Data
 */
export const TeamMembers = [
  {
    name: 'Rach David',
    position: 'CEO',
    imageUrl: CEO,
  },
  {
    name: 'Pauline Sydney',
    position: 'HRD',
    imageUrl: HRD,
  },
  {
    name: 'Granger Watterson',
    position: 'Finance',
    imageUrl: Finance,
  },
  {
    name: 'Tom Jimmy',
    position: 'Project Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Jim Hendrix',
    position: 'Front-end Developer',
    imageUrl: Frontend1,
  },
  {
    name: 'Calvin Max',
    position: 'Front-end Developer',
    imageUrl: Frontend2,
  },
  {
    name: 'Hawkins Jim',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Don Bizaro',
    position: 'Back-end Developer',
    imageUrl: Backend2,
  },
  {
    name: 'Bill Markinson',
    position: 'Mobile Developer',
    imageUrl: Mobile1,
  },
  {
    name: 'Igor Kavarov',
    position: 'Mobile Developer',
    imageUrl: Mobile2,
  },
  {
    name: 'Freddie Curl',
    position: 'UI/UX Designer',
    imageUrl: UIUX2,
  },
  {
    name: 'Monica Lovegood',
    position: 'UI/UX Designer',
    imageUrl: UIUX1,
  },
];
export const Blog = [
  {
    id: '1',
    title: 'October 2024',
    imageUrl: blog, // Reusing 'Recruiting.png' image
    description: 'We are excited to launch the Skill Builder Lesson Pack for Grade 6! Click here for more information about this new teacher-focused resource.',
    credit: 'https://yourwebsite.com/rcat-access', // Replace with actual credit link if applicable
  },
  {
    id: '2',
    title: 'September 2024',
    imageUrl: blog, // Reusing 'Recruiting.png' image
    description: 'The new RCAT platform now includes revised Screening Tests, Level Tests, and Passage assessments for grades three through twelve.',
   
    credit: 'https://yourwebsite.com/rcat-access', // Replace with actual credit link if applicable
  },
  {
    id: '3',
    title: 'August 2024',
    imageUrl: blog, // Reusing 'Recruiting.png' image
    description: 'We are pleased to announce the deployment of the newest version of the Reading Comprehension Assessment Tool (RCAT)!',

    credit: 'https://yourwebsite.com/rcat-access', // Replace with actual credit link if applicable
  },  
  {
    id: '4',
    title: 'June 2024 Newly released student workbooks',
    imageUrl: blog, // Reusing 'Recruiting.png' image
    description: 'June 2024: Newly released student workbooks published by Classroom Ready for Grades 5 to 10. Go to our store to see all the titles.',
    
    credit: 'https://yourwebsite.com/rcat-access', // Replace with actual credit link if applicable
  },
  {
    id: '5',
    title: 'May 2024',
    imageUrl: blog, // Reusing 'Recruiting.png' image
    description: 'All day Professional Development workshop with schools from Aspen View School Division held at Thorhild Central School on Reading Comprehension Success– such a pleasure to work with a wonderful group of educators!',
    
    credit: 'https://yourwebsite.com/rcat-access', // Replace with actual credit link if applicable
  }

];